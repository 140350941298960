import {
  Component,
  lazy,
  PropsWithChildren,
  Suspense,
  useLayoutEffect,
  useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import {
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
} from 'react-router';

import { initAuthentication, InitAuthenticationResult } from 'features/auth';
import { ROUTES } from 'pages/shared/routes';

import { APP_ELEMENT_ID } from './constants';
import { patchRouterForAddingTenantIdInUrl } from './patchRouter';

import './aspects/analytics/applicationInsights';

import './index.module.scss';

const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const WrappedApp = lazy(() => import('./WrappedApp'));

// https://github.com/remix-run/react-router/issues/9422#issuecomment-1302564759
// router is created so it is possible to patch navigate with adding tenantId
const router = patchRouterForAddingTenantIdInUrl(
  createBrowserRouter([
    // match everything with "*"
    { path: '*', element: <AppContainer /> },
  ]),
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById(APP_ELEMENT_ID)!).render(
  <RouterProvider router={router} />,
);

function AppContainer() {
  const authInitResult = useAuthInitResult();

  return authInitResult ? (
    <DynamicImportErrorBoundary>
      <Routes>
        <Route
          path={ROUTES.login}
          element={
            <LoginPage
              shouldShowErrorInitially={authInitResult.status === 'failed'}
            />
          }
        />
        <Route
          path="/*"
          element={
            authInitResult.status === 'ok' && (
              <Suspense fallback={null}>
                <WrappedApp authInitResult={authInitResult} />
              </Suspense>
            )
          }
        />
      </Routes>
    </DynamicImportErrorBoundary>
  ) : null;
}

// do not wait for react to render and start auth checks as soon as possible
const authInitPromise = initAuthentication(() => router.navigate(ROUTES.login));
function useAuthInitResult() {
  const [authInitResult, setAuthInitResult] = useState<
    InitAuthenticationResult | undefined
  >();

  useLayoutEffect(() => {
    authInitPromise.then(setAuthInitResult);
  }, []);

  return authInitResult;
}

// Fix this issue:
// https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
// https://github.com/vitejs/vite/issues/11804#issuecomment-1461068222
// given that we're not deploying too often and these cases on prod should happen pretty rarely,
// just reloading the app should be enough to tackle the issue
class DynamicImportErrorBoundary extends Component<PropsWithChildren> {
  static getDerivedStateFromError(error: unknown) {
    const loadFailedErrors = [
      'error loading a dynamically imported module',
      'failed to fetch dynamically imported module',
      'importing a module script failed',
    ];

    const errorMessage = (() => {
      if (error instanceof Error) {
        return error.message;
      }

      return typeof error === 'string' ? error : undefined;
    })();

    if (
      errorMessage &&
      loadFailedErrors.some((x) => errorMessage.toLowerCase().includes(x))
    ) {
      window.location.reload();
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return { hasError: true };
  }

  componentDidMount() {
    // https://vite.dev/guide/build#load-error-handling
    // not all errors are handled by the getDerivedStateFromError, with some errors vite triggers the vite:preloadError event;
    // for example, if user tries to load a CSS chunk with offline internet connection,
    // it won't be handled with getDerivedStateFromError, and we have to handle it with this event listener
    window.addEventListener('vite:preloadError', () => {
      window.location.reload();
    });
  }

  render() {
    return this.props.children;
  }
}
